<template>
  <div class="article flexColumn">
    <div
      :class="{ saveArticle: !myWidth, saveArticleMobile: myWidth }"
      v-if="!this.user"
      @click="link({ name: 'Signin' })"
    >
      <md-icon :class="{ 'md-size-2x': !myWidth, 'md-size-1x': myWidth }"
        >login</md-icon
      >
    </div>
    <div
      :class="{ saveArticle: !myWidth, saveArticleMobile: myWidth }"
      v-else-if="
        this.user.saved.map((article) => article._id).includes(article._id)
      "
        @click="removeArticle()"
    >
      <md-icon :class="{ 'md-size-2x': !myWidth, 'md-size-1x': myWidth }"
        >bookmark</md-icon
      >
    </div>
    <div
      :class="{ saveArticle: !myWidth, saveArticleMobile: myWidth }"
      v-else
      @click="addArticle()"
    >
      <md-icon :class="{ 'md-size-2x': !myWidth, 'md-size-1x': myWidth }"
        >bookmark_border</md-icon
      >
    </div>
    <div
      class="header flexColumn"
      :style="`background-image: linear-gradient(#00000041, #00000041), url(${article.image})`"
    >
      <div class="title">
        {{ article.title }}
      </div>
      <div class="subheader">
        {{ article.subheader }}
      </div>
    </div>
    <div class="content">
      <div class="meta">
        <div class="mainPoints" v-if="article.mainPoints.length">
          Main Points:
          <li v-for="point in article.mainPoints" v-bind:key="point">
            {{ point }}
          </li>
        </div>
        <div class="author">
          <div class="flexRow">
            <img :src="article.author.img"/>
            <div class="authorName">Author:<br/>{{ article.author.name }}</div>
          </div>
          <div
            class="academicDescription"
            v-if="article.author.academicDescription"
          >
            {{ article.author.academicDescription }}
          </div>
        </div>
        <div class="date" v-if="article.created">
          Published: {{ new Date(article.created).toLocaleString(myLanguage) }}
        </div>
        <div style="margin: 0 auto" class="share">
          <iframe
            :src="
              'https://www.facebook.com/plugins/share_button.php?href=' +
              articleLink +
              '&layout=button&size=large&width=77&height=28&appId'
            "
            width="77"
            height="28"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
          <div
            v-if="!myWidth"
            class="shareLink"
            @click="
              copyToClipboard();
              showSnackbar();
            "
          >
            Copy Link
          </div>
          <div id="snackbar">Copied Link!</div>
        </div>
        <!-- WIP - Maybe future Feature! -->
        <!--<h4 style="text-align: center">Comments</h4>
        <div class="comments">
          <div class="comment" v-for="comment in comments" v-bind:key="comment">
            <span class="commentAuthor">{{ comment.author }}</span
            >: <span class="commentText">{{ comment.text }}</span>
          </div>
        </div>-->
      </div>
      <div class="infoContainer">
        <div
            class="information"
            v-for="(paragraph, i) in article.content"
            v-bind:key="paragraph"
        >
          <div class="title" v-if="paragraph.title">
            {{ paragraph.title }}
          </div>
          <div class="image" v-if="paragraph.image">
            <img :src="paragraph.image"/>
            <i>{{ paragraph.imageDescription }}</i>
          </div>
          <div
              :class="i == 0 ? 'paragraph firstParagraph' : 'paragraph'"
              v-if="paragraph.text"
          >
            <vue-markdown>{{ paragraph.text }}</vue-markdown>
          </div>
        </div>
        <!-- Moved date to below author in new layout! -->
        <!--<div class="date" v-if="article.created">
          Published: {{ new Date(article.created).toLocaleString(myLanguage) }}
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "@adapttive/vue-markdown";
import store from "@/store";
import {logResource} from "../utils/logging";
import VueCookie from "vue-cookies";
import {axiosGet, axiosPost} from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      userEnteredTime: Date.now(),
      alreadyLeft: false,
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),

      article: {},
      articleLink: window.location.href,
      userId: store.state.accessToken,
      user: {},
      myWidth: screen.height / screen.width > 1,
      comments: [
        {
          author: "Test1",
          text: "hallo welt",
        },
        {
          author: "Test2",
          text: "hello world",
        },
        {
          author: "Lorem Ipsum1",
          text: "Proin odio ex, molestie quis neque at, consectetur condimentum neque. Fusce aliquet, leo sit amet finibus posuere, turpis nunc imperdiet dui, vitae auctor purus eros consequat nibh. Mauris pellentesque tincidunt diam, ac mattis dui porttitor vel. Cras eu lacinia elit. Pellentesque facilisis varius tortor. Sed fringilla consequat accumsan. Praesent dolor turpis, viverra id quam ut, congue vulputate purus. Aenean et mi eu ipsum dictum tempor nec eu neque. Nunc est arcu, imperdiet id turpis at, hendrerit malesuada tortor.",
        },
        {
          author: "Lorem Ipsum2",
          text: "Vestibulum feugiat, purus id rutrum viverra, justo augue pharetra mauris, nec placerat libero mauris id augue. Fusce iaculis metus a massa pharetra, eu consectetur lectus venenatis. Vestibulum egestas molestie nisi, eget elementum libero efficitur et. In vitae libero et nunc consequat interdum. Donec dui ex, cursus eget malesuada non, consectetur sed arcu. Ut eu condimentum elit, in hendrerit dui. Morbi sagittis nisi nec tellus facilisis, quis mollis urna porttitor.",
        },
      ],
    };
  },
  components: {
    VueMarkdown,
  },
  methods: {
    loadData() {
      return axiosGet("/users/id/" + this.userId)
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.warn(error);
          });
    },
    link(to) {
      this.$router
        .push(to)
        .catch((err) => console.warn(`Seems we are already here: ${err}`));
    },
    // Alternative Clipboard Copy function to be able to use in development area.
    // Source: https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
    copyToClipboard() {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(this.articleLink);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = this.articleLink;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    showSnackbar() {
      var x = document.getElementById("snackbar");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 2000);
    },
    async addArticle() {
      await axiosPost("/users/article/save", {
        content: this.article,
        userId: this.userId,
      });
      this.user = await this.loadData();
    },
    async removeArticle() {
      await axiosPost("/users/article/remove", {
        content: this.article,
        userId: this.userId,
      });
      this.user = await this.loadData();
    },
    async aboutToLeave() {
      if (!this.alreadyLeft) {  // Makes sure, no data is saved twice
        this.alreadyLeft = true
        const uuid = VueCookie.get('uuid')
        const timeSpend = (Date.now() - this.userEnteredTime) / 1000;
        if (timeSpend > 2 && timeSpend < (60*60*12)) // Else we dont consider the user to actually be interested
          await logResource(
              this.article.title,
              this.article.author.name,
              this.article._id,
              uuid,
              timeSpend
          )
      }
    }
  },
  async mounted() {
    window.addEventListener('beforeunload', this.aboutToLeave) // Fires when page is refreshed

    this.article = (
        await axiosGet(
            "/selfSupport/article/id/" +
            this.$router.currentRoute.params.name
        )
    ).data;
    this.user = await this.loadData();
  },
  async beforeDestroy() { // Fires when page is left by a link
    await this.aboutToLeave()
  }
};
</script>

<style scoped>
.article {
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.header {
  --width: 100vw;
  background-size: 100% 100%;
  width: var(--width);
  height: calc(var(--width) * (1668 / 2388));
  max-width: 1700px;
  max-height: calc(1700px * (1668 / 2388));
  justify-content: center;
  background-blend-mode: darken;
  color: #ffffff;
  overflow: auto;
}

.header .title {
  font-size: 4em;
  line-height: 2em;
  font-weight: bold;
  text-shadow: 0px 0px 10px black;
}

.header .subheader {
  font-size: 3em;
  line-height: 1.5em;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1700px;
  margin: 50px;
}

.date {
  text-align: right;
  margin: 25px 10%;
}

.meta {
  width: 30%;
  margin: 50px 5%;
  margin-top: 25px;
  text-align: left;
  font-size: 1em;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
  /* Leads to stretched out Divs -> manual spacing */
  /* justify-content: space-between; */
  font-family: var(--text);
}

.meta .author {
  margin-top: 50px;
  font-size: 1.5em;
  text-align: left;
}

.meta .author img {
  box-sizing: border-box;
  width: 50%;
  object-fit: cover;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid gray;
  box-shadow: 0px 0px 10px #ccc;
}

.meta .authorName {
  width: 50%;
  margin: 0 auto;
  margin-top: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
  box-sizing: border-box;
  border-left: 1px dashed #ccc;
  margin-left: 15px;
  height: 50%;
}

.meta .author .academicDescription {
  font-size: 0.75em;
  padding: 5px;
  background: #f9f9f9;
  border-left: 5px solid #ccc;
  margin-top: 10px;
}
.share {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  height: 28px;
}
.shareLink {
  padding-top: 4px;
  background: #ddd;
  border-radius: 5px;
  width: 100px;
  height: 28px;
  text-align: center;
}
.shareLink:hover {
  cursor: pointer;
}
/* SNACKBAR */
#snackbar {
  visibility: hidden;
  max-width: 200px;
  background-color: rgba(0, 0, 0, 0.66);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;

  position: absolute;
  float: right;
  left: 0;
  right: 0;
  bottom: 35px;
  margin: 0 auto;

  z-index: 1;
  transition: 0.5s;
  transform: scale(0);
}
#snackbar.show {
  visibility: visible;
  /* -webkit-animation: fadein 1s, fadeout 0.5s 2.5s;
  animation: fadein 1s, fadeout 0.5s 2.5s;  */
  transform: scale(1.2);
}

.comments {
  display: flex;
  flex-direction: column;
  max-height: 240px;
  overflow: auto;
}
.comment {
  padding: 5px;
}
.comments::-webkit-scrollbar {
  width: 0.75em;
}

.comments::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.comments::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border: 1px solid slategrey;
  border-radius: 5px;
}
.commentAuthor {
  font-weight: bold;
}
.commentText {
  color: black;
}

.meta .mainPoints {
  font-size: 2rem;
  font-weight: bold;
}

.meta .mainPoints li {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: normal;
  padding: 5px;
}

.meta .mainPoints li:first-child {
  padding-top: 0.75em;
}

.infoContainer {
  width: 70%;
}

.information {
  display: flex;
  flex-direction: column;
  color: #000000;
  margin-bottom: 50px;
  margin-right: 50px;
}

.information .firstParagraph {
  display: inline-block;
}

.information .firstParagraph::first-letter {
  font-size: 2em;
  font-weight: bold;
}

.information .title {
  font-family: var(--articleh1);
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 900;
  text-align: justify;
}

.information .paragraph {
  font-family: var(--articleText);
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: justify;
}

.information .image {
  font-size: 1em;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  text-align: left;
  align-self: center;
  font-family: var(--text);
  padding-top: 2em;
}

.information .image img {
  border: #00000044 solid;
}

.saveArticle,
.saveArticleMobile {
  background-color: var(--c3);
  color: white;
  border-radius: 50%;
  padding: 10px;
  position: fixed;
  bottom: 30px;
  left: 30px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.saveArticleMobile {
  bottom: 10px;
  left: 10px;
}

.saveArticle:hover,
.saveArticleMobile:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 1700px) {
  .content {
    max-width: 95%;
  }
}

@media only screen and (max-width: 640px) {
  .content {
    flex-direction: column;
    font-family: var(--text);
  }

  .meta {
    width: 95%;
    margin: 0 2.5%;
  }

  .date {
    width: 100%;
    margin-top: 0;
    padding-top: 10px;
    border-top: 1px dashed black;
    padding-right: 10%;
  }

  .information {
    width: 100%;
    margin-bottom: 0;
  }

  .information .title {
    font-size: 2em;
    text-align: left;
  }

  .information .paragraph {
    font-size: 1.5em;
    line-height: 1.3em;
  }

  .information .image {
    max-width: 90%;
  }

  .infoContainer {
    width: 95%;
    margin: 0 2.5%;
    border-top: 1px dashed black;
    padding-top: 10px;
    margin-top: 20px;
  }

  .author {
    border-top: 1px dashed black;
    margin-top: 10px;
    padding-top: 10px;
  }

  .header .title {
    font-size: 2em;
    line-height: 1.5em;
    font-weight: bold;
  }

  .header .subheader {
    font-size: 2em;
    line-height: 1.5em;
  }

  .meta {
    font-size: 0.8em;
  }

  .meta .mainPoints {
    font-size: 2em;
  }

  .meta .mainPoints li {
    font-size: 0.9em;
    line-height: 1.2em;
  }
}
</style>
