import {axiosPostLog} from "@/utils/axiosWrapper";

async function logResource(resourceName, resourceInfo, resourceId, userId, userTime){
    const data = {
        resourceName,
        resourceInfo,
        resourceId,
        userId,
        userTime,
    }
    try{
        await axiosPostLog('/resources', data)
    }
    catch(err){
        console.warn(err)
    }
}
export { logResource }